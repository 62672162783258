import MaingridRight from "./MaingridRight";
import Filterpaper from "./Filterpaper";
import { useEffect, useState, useContext, createContext, useMemo } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import DataDisplay from "./DataDisplay";
import Usercard from "./Usercard";
import TableRight from "./TableRight";

const defaultFilterOptions = {
  studentName: "",
  studentClasses: [],
  sortBy: "Class",
  Term: "GS Average",
  gradeRange: [1, 4],
  studentFeature: [],
  studentFeatureEx: [],
};

export default function Maingrid(props) {
  const [dataset, setDataset] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState();
  const [dataSelection, setDataSelection] = useState([]);
  const [imageBlobs, addImageBlob] = useState([]);
  const [currentPageRight, setCurrentPageRight] = useState(1);
  const [currentPageTable, setCurrentPageTable] = useState(0);
  const [currentView, setCurrentView] = useState("grid");
  const [includedClasses, setIncludedClasses] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [allEmojis, setAllEmojis] = useState([]);

  const [includedTerms, setIncludedTerms] = useState([
    "GS Average",
    "JS Average",
  ]);
  const [filterOptions, setFilterOptions] = useState(defaultFilterOptions);

  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  function changeFilterOptions(event) {
    console.log(event);
    if (event == "resetAll") {
      setFilterOptions(defaultFilterOptions);
    } else if (event == "syncEmojiFeature") {
      let value = filterOptions["studentFeature"].filter((feature) => {
        return /[\p{Emoji}]/u.test(feature)
          ? allEmojis.includes(feature)
            ? true
            : false
          : true;
      });
      if (
        JSON.stringify(filterOptions["studentFeature"]) !==
        JSON.stringify(value)
      ) {
        setFilterOptions({
          ...filterOptions,
          ["studentFeature"]: value,
        });
      }
      if (
        JSON.stringify(filterOptions["studentFeatureEx"]) !==
        JSON.stringify(value)
      ) {
        setFilterOptions({
          ...filterOptions,
          ["studentFeatureEx"]: value,
        });
      }
    } else {
      setFilterOptions({
        ...filterOptions,
        [event.target.name]: event.target.value,
      });
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      let attempts = 0;
      while (attempts < 4) {
        try {
          let newToken = await props.refreshToken();
          let appCheckToken = await props.getAppCheckToken();

          const response = await fetch(
            "https://teacher-dashboard-427618.nn.r.appspot.com/getStudentInfo", //https://teacher-dashboard-427618.nn.r.appspot.com/getStudentInfo http://127.0.0.1:5000/getStudentInfo
            {
              method: "GET",
              headers: {
                "X-Authorization-Token": newToken,
                "X-Firebase-AppCheck": appCheckToken.token,
              },
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setRawData(data);
          setLoaded(true);
          handleClose();

          let addedClases = [];
          let addedTerms = [];
          data.forEach((student) => {
            if (!includedClasses.includes(student[5])) {
              if (!addedClases.includes(student[5])) {
                addedClases.push(student[5]);
                setIncludedClasses((prevClasses) => [
                  ...prevClasses,
                  student[5],
                ]);
              }
            }

            let viewmountFiltered = addedClases.filter((element) =>
              element.includes("V")
            );
            let himelFiltered = addedClases.filter((element) =>
              element.includes("H")
            );

            viewmountFiltered.length > 1 &&
              himelFiltered.length > 0 &&
              !addedClases.includes("All Viewmount") &&
              addedClases.push("All Viewmount") &&
              setIncludedClasses((prevClasses) => [
                "All Viewmount",
                ...prevClasses,
              ]);

            himelFiltered.length > 1 &&
              viewmountFiltered.length > 0 &&
              !addedClases.includes("All Himel") &&
              addedClases.push("All Himel") &&
              setIncludedClasses((prevClasses) => [
                "All Himel",
                ...prevClasses,
              ]);

            student[42]
              .replaceAll("•", "")
              .replaceAll(" ", "")
              .replaceAll("SocialStudies", "Social Studies")
              .split(/:|\n/)
              .forEach((term) => {
                if (!addedTerms.includes(term) && isNaN(term)) {
                  addedTerms.push(term);
                  setIncludedTerms((prevTerms) => [...prevTerms, term]);
                }
              });
          });

          // If the request was successful, break out of the loop
          break;
        } catch (error) {
          console.error("Attempt to fetch data failed:", error);
          attempts += 1;

          // If this was the last attempt, throw the error
          if (attempts >= 4) {
            console.error("Failed to fetch data after 4 attempts:", error);
            throw error;
          }
        }
      }
    };

    fetchData();
  }, []);

  function repopulateDataset(studentId) {
    for (const i in dataset) {
      if (dataset[i][1] == studentId) {
        setDataSelection(dataset[i]);

        break;
      }
    }
  }

  useEffect(() => {
    for (const i in dataset) {
      if (dataset[i][1] == selectedStudent) {
        setDataSelection(dataset[i]);

        break;
      }
    }
  }, [selectedStudent]);

  let termMarksAvg = function (student) {
    let term1Marks = [];
    let term2Marks = [];
    let allAvgs = [];

    term1Marks = student[42]
      .replaceAll("•", "")
      .replaceAll(" ", "")
      .replaceAll("SocialStudies", "Social Studies")
      .split(/:|\n/);

    if (student[43] != "") {
      term2Marks = student[43]
        .replaceAll("•", "")
        .replaceAll(" ", "")
        .replaceAll("SocialStudies", "Social Studies")
        .split(/:|\n/);
    }
    if (student[43] == "" && student[42] != "") {
      term2Marks = term1Marks;
    } else if (student[43] != "" && student[42] == "") {
      term1Marks = term2Marks;
    }

    allAvgs = term1Marks.map((mark, index) => {
      if (index % 2 != 0 && term2Marks.indexOf(term1Marks[index - 1]) != -1) {
        return parseFloat(
          (Number(term1Marks[index]) +
            Number(term2Marks[term2Marks.indexOf(term1Marks[index - 1]) + 1])) /
            2
        ).toFixed(2);
      } else {
        return mark;
      }
    });

    return allAvgs;
  };

  useMemo(() => {
    setCurrentPageRight(1);
    setCurrentPageTable(0);
  }, [
    filterOptions["studentName"],
    filterOptions["studentClasses"],
    filterOptions["sortBy"],
    filterOptions["Term"],
    filterOptions["gradeRange"],
    filterOptions["studentFeature"],
    filterOptions["studentFeatureEx"],
    rawData,
  ]);

  useMemo(() => {
    changeFilterOptions("syncEmojiFeature");
  }, [allEmojis]);

  useMemo(() => {
    console.log(filterOptions);
    let filteredData = rawData;

    //filter by grade

    if (
      filterOptions["gradeRange"][0] != 1 ||
      filterOptions["gradeRange"][1] != 4
    ) {
      filteredData = filteredData.filter(
        (student) =>
          (filterOptions["Term"] == "GS Average" &&
            ((Number(student[16]) || 0) + (Number(student[18]) || 0)) /
              ((student[16] && 1) + (student[18] && 1)) >=
              filterOptions["gradeRange"][0] &&
            ((Number(student[16]) || 0) + (Number(student[18]) || 0)) /
              ((student[16] && 1) + (student[18] && 1)) <=
              filterOptions["gradeRange"][1] &&
            ((Number(student[16]) || 0) + (Number(student[18]) || 0)) /
              ((student[16] && 1) + (student[18] && 1)) !=
              "") ||
          (filterOptions["Term"] == "JS Average" &&
            ((Number(student[17]) || 0) + (Number(student[19]) || 0)) /
              ((student[17] && 1) + (student[19] && 1)) >=
              filterOptions["gradeRange"][0] &&
            ((Number(student[17]) || 0) + (Number(student[19]) || 0)) /
              ((student[17] && 1) + (student[19] && 1)) <=
              filterOptions["gradeRange"][1] &&
            ((Number(student[17]) || 0) + (Number(student[19]) || 0)) /
              ((student[17] && 1) + (student[19] && 1)) !=
              "") ||
          (termMarksAvg(student).includes(filterOptions["Term"]) &&
            (termMarksAvg(student)[
              termMarksAvg(student).indexOf(filterOptions["Term"]) + 1
            ] || 0) >= filterOptions["gradeRange"][0] &&
            termMarksAvg(student)[
              termMarksAvg(student).indexOf(filterOptions["Term"]) + 1
            ] <= filterOptions["gradeRange"][1])
      );
    }

    // Filter by student name if the filter is not empty
    if (filterOptions["studentName"] !== "") {
      filteredData = filteredData.filter(
        (student) =>
          student[4]
            .toUpperCase()
            .includes(filterOptions["studentName"].toUpperCase()) ||
          student[6]
            .toUpperCase()
            .includes(filterOptions["studentName"].toUpperCase())
      );
    }

    // Filter by student classes if the filter is not empty
    if (filterOptions["studentClasses"].length > 0) {
      filteredData = filteredData.filter(
        (student) =>
          filterOptions["studentClasses"].includes(student[5]) ||
          (filterOptions["studentClasses"].includes("All Himel") &&
            student[5].includes("H")) ||
          (filterOptions["studentClasses"].includes("All Viewmount") &&
            student[5].includes("V"))
      );
    }

    // Filter by Tag
    if (filterOptions["studentFeature"].length > 0) {
      filteredData = filteredData.filter(
        (student) =>
          (filterOptions["studentFeature"].includes("IEP") &&
            student[22] != "") ||
          (filterOptions["studentFeature"].includes("Growth Plan") &&
            student[23] != "") ||
          (filterOptions["studentFeature"].includes("SSF") &&
            student[25] != "") ||
          (filterOptions["studentFeature"].includes("New to Bialik (SK+)") &&
            student[41] != "" &&
            !student[5].includes("JK")) ||
          (filterOptions["studentFeature"].includes("Shiluv") &&
            student[29].includes("24/25")) ||
          (filterOptions["studentFeature"].includes("Ulpan") &&
            student[28].includes("24/25")) ||
          (filterOptions["studentFeature"].includes("Anaphylaxis") &&
            student[46] == "Yes") ||
          (filterOptions["studentFeature"].includes("Asthma") &&
            student[48] == "Yes") ||
          (filterOptions["studentFeature"].includes("Serious Condition") &&
            student[50] == "Yes") ||
          (filterOptions["studentFeature"].includes("No Photo Release") &&
            student[55] == "No") ||
          (filterOptions["studentFeature"].includes("Ivrit MOD") &&
            student[57].includes("MOD")) ||
          (filterOptions["studentFeature"].includes("Tanach MOD") &&
            student[58].includes("MOD")) ||
          (filterOptions["studentFeature"].includes("ELL") &&
            student[59] != "") ||
          (filterOptions["studentFeature"].includes("Kippah Yes") &&
            student[60] == "Wear") ||
          (filterOptions["studentFeature"].includes("Kippah No") &&
            student[60] == "Not wear") ||
          (filterOptions["studentFeature"].includes("Kippah Student") &&
            student[60] == "Up to child") ||
          (filterOptions["studentFeature"].includes("HT") &&
            student[67] == "Haifa Technion") ||
          (filterOptions["studentFeature"].includes("BITA") &&
            student[67] == "Bar Ilan Tel Aviv") ||
          (filterOptions["studentFeature"].includes("BGW") &&
            student[67] == "Ben Gurion Weizmann") ||
          (filterOptions["studentFeature"].includes("HU") &&
            student[67] == "Hebrew University") ||
          (filterOptions["studentFeature"].includes("PTA Parent") &&
            student[68].includes("PTA Parent")) ||
          (filterOptions["studentFeature"].includes("Divorced Household") &&
            student[68].includes("Divorced Household")) ||
          (filterOptions["studentFeature"].includes("Staff Child") &&
            student[68].includes("Staff child (Current)")) ||
          (filterOptions["studentFeature"].includes("Former Staff Child") &&
            student[68].includes("Staff child (Former)")) ||
          (filterOptions["studentFeature"].includes("Deaf Parent") &&
            student[68].includes("Deaf Parent")) ||
          (filterOptions["studentFeature"].includes("Board Parent") &&
            student[68].includes("Board Parent")) ||
          filterOptions["studentFeature"].some(
            (tag) =>
              props.customEmojiTag[student[1]] &&
              props.customEmojiTag[student[1]].includes(tag)
          )
      );
    }

    // Exclude by Tag
    if (filterOptions["studentFeatureEx"].length > 0) {
      console.log("more");
      filteredData = filteredData.filter(
        (student) =>
          !(
            filterOptions["studentFeatureEx"].includes("IEP") &&
            student[22] != ""
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Growth Plan") &&
            student[23] != ""
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("SSF") &&
            student[25] != ""
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("New to Bialik (SK+)") &&
            student[41] != "" &&
            !student[5].includes("JK")
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Shiluv") &&
            student[29].includes("24/25")
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Ulpan") &&
            student[28].includes("24/25")
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Anaphylaxis") &&
            student[46] == "Yes"
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Asthma") &&
            student[48] == "Yes"
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Serious Condition") &&
            student[50] == "Yes"
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("No Photo Release") &&
            student[55] == "No"
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Ivrit MOD") &&
            student[57].includes("MOD")
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Tanach MOD") &&
            student[58].includes("MOD")
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("ELL") &&
            student[59] != ""
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Kippah Yes") &&
            student[60] == "Wear"
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Kippah No") &&
            student[60] == "Not wear"
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Kippah Student") &&
            student[60] == "Up to child"
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("HT") &&
            student[67] == "Haifa Technion"
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("BITA") &&
            student[67] == "Bar Ilan Tel Aviv"
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("BGW") &&
            student[67] == "Ben Gurion Weizmann"
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("HU") &&
            student[67] == "Hebrew University"
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("PTA Parent") &&
            student[68].includes("PTA Parent")
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Divorced Household") &&
            student[68].includes("Divorced Household")
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Staff Child") &&
            student[68].includes("Staff child (Current)")
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Former Staff Child") &&
            student[68].includes("Staff child (Former)")
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Deaf Parent") &&
            student[68].includes("Deaf Parent")
          ) &&
          !(
            filterOptions["studentFeatureEx"].includes("Board Parent") &&
            student[68].includes("Board Parent")
          ) &&
          !filterOptions["studentFeatureEx"].some(
            (tag) =>
              props.customEmojiTag[student[1]] &&
              props.customEmojiTag[student[1]].includes(tag)
          )
      );
    }

    if (filterOptions["sortBy"] == "Class") {
      filteredData.sort((a, b) => {
        if (a[5] < b[5]) {
          return -1;
        }
        if (a[5] > b[5]) {
          return 1;
        }
        // Now comparing by [2] if [5] is equal
        if (a[2] < b[2]) {
          return -1;
        }
        if (a[2] > b[2]) {
          return 1;
        }
        return 0;
      });
    } else if (filterOptions["sortBy"] == "First Name") {
      filteredData.sort((a, b) => {
        if (a[2] < b[2]) {
          return -1;
        }
        if (a[2] > b[2]) {
          return 1;
        }
        return 0;
      });
    } else if (filterOptions["sortBy"] == "Last Name") {
      filteredData.sort((a, b) => {
        if (a[3] < b[3]) {
          return -1;
        }
        if (a[3] > b[3]) {
          return 1;
        }
        return 0;
      });
    } else if (filterOptions["sortBy"] == "Birthdate") {
      filteredData.sort((a, b) => {
        if (new Date(a[56]) < new Date(b[56])) {
          return -1;
        }
        if (new Date(a[56]) > new Date(b[56])) {
          return 1;
        }
        return 0;
      });
    }

    setDataset(filteredData);
  }, [
    filterOptions["studentName"],
    filterOptions["studentClasses"],
    filterOptions["sortBy"],
    filterOptions["Term"],
    filterOptions["gradeRange"],
    filterOptions["studentFeature"],
    filterOptions["studentFeatureEx"],
    rawData,
    props.customEmojiTag,
  ]);

  const selectNewStudent = function (studentId) {
    if (studentId) {
      setSelectedStudent(studentId);
      repopulateDataset(studentId);
    } else {
      setDataSelection([]);
      setSelectedStudent(null);
    }
  };

  const setNewImageBlob = function (studentId, blob) {
    addImageBlob((prevBlobs) => [...prevBlobs, { studentId, blob }]);
  };

  useEffect(() => {
    let rawEmojiArray = [];

    for (let i in props.customEmojiTag) {
      for (let x in props.customEmojiTag[i]) {
        !rawEmojiArray.includes(props.customEmojiTag[i][x]) &&
          rawEmojiArray.push(props.customEmojiTag[i][x]);
      }
    }

    setAllEmojis(rawEmojiArray);
  }, [props.customEmojiTag]);

  return (
    <div className="maingrid--flex">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="maingrid--itm maingrid--left">
        {dataSelection.length ? (
          <Usercard
            studentName={dataSelection[4]}
            studentClass={dataSelection[5]}
            customEmojiTag={props.customEmojiTag}
            hebrewName={dataSelection[6]}
            studentEmail={dataSelection[34]}
            parentEmails={dataSelection[39]}
            refreshToken={props.refreshToken}
            phoneNumbers={dataSelection[45]}
            photoReleaseAllowed={dataSelection[55] == "No"}
            getAppCheckToken={props.getAppCheckToken}
            studentID={selectedStudent}
            filteredData={dataset}
            imageDriveUrl={dataSelection[dataSelection.length - 3]}
            inSidebar
            newStudent={selectNewStudent}
            setNewImageBlob={setNewImageBlob}
            changeStudentEmojiTag={props.changeStudentEmojiTag}
            imageBlobs={imageBlobs}
            setHelpOpen={props.setHelpOpen}
            isNewToBialik={
              dataSelection[41] != "" && !dataSelection[5].includes("JK")
            }
            tags={[
              ["IEP", dataSelection[22] != ""],
              ["GP", dataSelection[23] != ""],
              ["SSF", dataSelection[25] != ""],
              ["Shiluv", dataSelection[29].includes("24/25")],
              ["Ulpan", dataSelection[28].includes("24/25")],
              ["Anaphylaxis", dataSelection[46] == "Yes"],
              ["Asthma", dataSelection[48] == "Yes"],
              ["Serious Condition", dataSelection[50] == "Yes"],
              ["Ivrit MOD", dataSelection[57].includes("MOD")],
              ["Tanach MOD", dataSelection[58].includes("MOD")],
              ["ELL", dataSelection[59] != ""],
              ["PTA Parent", dataSelection[68].includes("PTA Parent")],
              [
                "Divorced Household",
                dataSelection[68].includes("Divorced Household"),
              ],
              [
                "Staff Child",
                dataSelection[68].includes("Staff child (Current)"),
              ],
              [
                "Former Staff Child",
                dataSelection[68].includes("Staff child (Former)"),
              ],
              ["Deaf Parent", dataSelection[68].includes("Deaf Parent")],
              ["Board Parent", dataSelection[68].includes("Board Parent")],
              ["Kippah Yes", dataSelection[60] == "Wear"],
              ["Kippah No", dataSelection[60] == "Not wear"],
              ["Kippah Student", dataSelection[60] == "Up to child"],
              [
                "BGW",
                dataSelection[67] == "Ben Gurion Weizmann",
                "#d44f46",
                "#ffffff",
              ],
              [
                "HT",
                dataSelection[67] == "Haifa Technion",
                "#64ab2e",
                "#ffffff",
              ],
              [
                "HU",
                dataSelection[67] == "Hebrew University",
                "#000000",
                "#e0bd22",
              ],
              ["BITA", dataSelection[67] == "Bar Ilan Tel Aviv", "#FFD700"],
            ]}
          />
        ) : (
          <Filterpaper
            logoutFn={props.logoutFn}
            setCurrentView={setCurrentView}
            currentView={currentView}
            filterOptions={filterOptions}
            changeFilterOptions={changeFilterOptions}
            defaultFilterOptions={defaultFilterOptions}
            includedClasses={includedClasses}
            includedTerms={includedTerms}
            filteredData={dataset}
            customEmojiTag={props.customEmojiTag}
            allEmojis={allEmojis}
            setAllEmojis={setAllEmojis}
          />
        )}
      </div>
      <div
        className={
          "maingrid--itm " +
          ((currentView == "grid" || dataSelection.length) && "maingrid--right")
        }
      >
        {dataSelection.length ? (
          <DataDisplay
            dataset={dataset}
            refreshToken={props.refreshToken}
            selectedStudent={selectedStudent}
            dataSelection={dataSelection}
            rawData={rawData}
          />
        ) : currentView == "grid" ? (
          <MaingridRight
            dataset={dataset}
            loaded={loaded}
            refreshToken={props.refreshToken}
            newStudent={selectNewStudent}
            setNewImageBlob={setNewImageBlob}
            setTooltipStatus={props.setTooltipStatus}
            tooltipPrevSet={props.tooltipPrevSet}
            imageBlobs={imageBlobs}
            customEmojiTag={props.customEmojiTag}
            currentPage={currentPageRight}
            setCurrentPage={setCurrentPageRight}
            getAppCheckToken={props.getAppCheckToken}
            filterOptions={filterOptions}
            includedTerms={includedTerms}
            termMarksAvg={termMarksAvg}
            setHelpOpen={props.setHelpOpen}
            changeStudentEmojiTag={props.changeStudentEmojiTag}
          />
        ) : (
          <TableRight
            dataset={dataset}
            loaded={loaded}
            refreshToken={props.refreshToken}
            customEmojiTag={props.customEmojiTag}
            newStudent={selectNewStudent}
            currentPageTable={currentPageTable}
            setCurrentPageTable={setCurrentPageTable}
            filterOptions={filterOptions}
            includedTerms={includedTerms}
            termMarksAvg={termMarksAvg}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            setHelpOpen={props.setHelpOpen}
          />
        )}
      </div>
    </div>
  );
}
