import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import StarIcon from "@mui/icons-material/Star";
import Typography from "@mui/material/Typography";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";

export default function TableRight(props) {
  const selectedTerm = props.filterOptions["Term"];

  const columns = [
    { id: "firstName", label: "First Name" },
    { id: "lastName", label: "Last Name" },
    { id: "hebrewName", label: "Hebrew Name" },
    { id: "studentClass", label: "Class" },
    {
      id: "jsAvg",
      label: props.filterOptions["Term"],
    },
    { id: "birthdate", label: "Birthdate" },
    { id: "selectRow", label: "" },
    { id: "chipRow", label: "" },
  ];

  const [rowData, setRowData] = useState([]);

  let rowsPerPage = props.rowsPerPage;
  let setRowsPerPage = props.setRowsPerPage;

  const page = props.currentPageTable;
  const setPage = props.setCurrentPageTable;

  const dataset = props.dataset;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function createData(
    firstName,
    lastName,
    hebrewName,
    studentClass,
    jsAvg,
    birthdate,
    selectRow,
    chipRow
  ) {
    setRowData((prevRowData) => [
      ...prevRowData,
      {
        firstName,
        lastName,
        hebrewName,
        studentClass,
        jsAvg,
        birthdate,
        selectRow,
        chipRow,
      },
    ]);
  }

  useEffect(() => {
    const allStudents =
      (setRowData([]),
      dataset.map((student, index) => {
        const tags = [
          ["IEP", student[22] != ""],
          ["GP", student[23] != ""],
          ["SSF", student[25] != ""],
          ["Shiluv", student[29].includes("24/25")],
          ["Ulpan", student[28].includes("24/25")],
          ["Anaphylaxis", student[46] == "Yes"],
          ["Asthma", student[48] == "Yes"],
          ["Serious Condition", student[50] == "Yes"],
          ["Ivrit MOD", student[57].includes("MOD")],
          ["Tanach MOD", student[58].includes("MOD")],
          ["ELL", student[59] != ""],
          ["PTA Parent", student[68].includes("PTA Parent")],
          ["Divorced Household", student[68].includes("Divorced Household")],
          ["Staff Child", student[68].includes("Staff child (Current)")],
          ["Former Staff Child", student[68].includes("Staff child (Former)")],
          ["Deaf Parent", student[68].includes("Deaf Parent")],
          ["Board Parent", student[68].includes("Board Parent")],
          ["Kippah Yes", student[60] == "Wear"],
          ["Kippah No", student[60] == "Not wear"],
          ["Kippah Student", student[60] == "Up to child"],
          ["BGW", student[67] == "Ben Gurion Weizmann", "#d44f46", "#ffffff"],
          ["HT", student[67] == "Haifa Technion", "#64ab2e", "#ffffff"],
          ["HU", student[67] == "Hebrew University", "#000000", "#e0bd22"],
          ["BITA", student[67] == "Bar Ilan Tel Aviv", "#FFD700"],
        ];
        const tagmap = tags.map((tag, index) => {
          if (tag[1])
            return (
              <Chip
                sx={{
                  marginRight: "5px",
                  marginTop: "5px",
                  backgroundColor: tag[2] && tag[2],
                  color: tag[3] && tag[3],
                }}
                label={tag[0]}
                key={tag[0] + index}
              />
            );
        });

        let jsAvg =
          (props.filterOptions["Term"] == "GS Average" &&
            (((Number(student[16]) || 0) + (Number(student[18]) || 0)) /
              ((student[16] && 1) + (student[18] && 1)) ||
              false)) ||
          (props.filterOptions["Term"] == "JS Average" &&
            (((Number(student[17]) || 0) + (Number(student[19]) || 0)) /
              ((student[17] && 1) + (student[19] && 1)) ||
              false)) ||
          Number(
            props.termMarksAvg(student)[
              props.termMarksAvg(student).indexOf(props.filterOptions["Term"]) +
                1
            ]
          ) ||
          false;
        createData(
          student[2],
          student[3],
          student[6],
          <a
            href="#"
            onClick={() => props.setHelpOpen([true, "opts", student[5]])}
          >
            {student[5]}
          </a>,

          typeof jsAvg == "number" ? jsAvg.toFixed(2) : "",
          (() => {
            const [year, month, day] = student[56].split("-");
            const date = new Date(year, month - 1, day, 12); // Set time to noon to avoid timezone issues
            return date.toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
          })(),
          <Button
            className="centerButton"
            variant="outlined"
            onClick={() => props.newStudent(student[1])}
            key={student[2] + index}
          >
            View Student
          </Button>,

          <>
            {!tags.every((v) => v == null) && (
              <>
                {props.customEmojiTag[student[1]] &&
                  props.customEmojiTag[student[1]].map((emoji, index) => (
                    <Chip
                      key={emoji + index + 1}
                      label={emoji}
                      variant="outlined"
                      sx={{
                        margin: "5px",
                        backgroundColor: "rgb(255,255,255)",
                      }}
                    />
                  ))}
                {student[41] != "" && !student[5].includes("JK") && (
                  <Chip
                    icon={<StarIcon />}
                    key={student[1] + "newT"}
                    sx={{
                      backgroundColor: "rgba(255, 209, 5, 0.5)",
                      marginRight: "5px",
                      marginTop: "5px",
                    }}
                    label="New to Bialik"
                  />
                )}
                {student[55] == "No" && (
                  <Chip
                    icon={<NoPhotographyIcon />}
                    key={student[1] + "noPhoto"}
                    sx={{
                      backgroundColor: "rgba(255, 5, 5, 0.5)",
                      marginRight: "5px",
                      marginTop: "5px",
                    }}
                    label="No Photo Release"
                  />
                )}

                <div className="tagsDiv" key={student[2] + "tagmapKey"}>
                  {tagmap}
                </div>
              </>
            )}
          </>
        );
      }));
  }, [props]);

  return dataset.length ? (
    <Paper sx={{ width: "100%", overflowY: "auto", maxHeight: "100%" }}>
      <TableContainer sx={{}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.id + index}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 12, 25, 100, 200, 500]}
        component="div"
        count={rowData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  ) : (
    <h3 className="centerPagination"> {props.loaded && "No Results"} </h3>
  );
}
